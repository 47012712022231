.rce-container-mbox {
  display: block;
  flex-direction: column;
  min-width: 250px;
  overflow-x: hidden;
  padding: 2px 0;
}
.rce-mbox-forward {
  align-items: center;
  align-self: center;
  background: #fff;
  border-radius: 20px;
  bottom: 0;
  box-shadow: 0 0 5px 0 #a4a4a4;
  cursor: pointer;
  flex-direction: row;
  height: 30px;
  justify-content: center;
  margin: auto;
  position: absolute;
  top: 0;
  transition: all 0.3s ease;
  width: 30px;
}
.rce-mbox-forward-left {
  display: flex;
  left: -50px;
  opacity: 0;
  visibility: hidden;
}
.rce-mbox-forward-right {
  display: flex;
  opacity: 0;
  right: -50px;
  visibility: hidden;
}
.rce-mbox-reply-btn-left {
  display: flex;
  left: -85px;
  opacity: 0;
  visibility: hidden;
}
.rce-mbox-reply-btn-right {
  display: flex;
  opacity: 0;
  right: -85px;
  visibility: hidden;
}
.rce-container-mbox:hover .rce-mbox-forward-left,
.rce-container-mbox:hover .rce-mbox-forward-right {
  opacity: 1;
  visibility: visible;
}
.rce-mbox-remove {
  align-items: center;
  align-self: center;
  background: #fff;
  border-radius: 20px;
  bottom: 0;
  box-shadow: 0 0 5px 0 #a4a4a4;
  cursor: pointer;
  flex-direction: row;
  height: 30px;
  justify-content: center;
  margin: auto;
  position: absolute;
  top: 0;
  transition: all 0.3s ease;
  width: 30px;
}
.rce-mbox-remove-left {
  display: flex;
  left: -120px;
  opacity: 0;
  visibility: hidden;
}
.rce-mbox-remove-right {
  display: flex;
  opacity: 0;
  right: -120px;
  visibility: hidden;
}
.rce-container-mbox:hover .rce-mbox-remove-left,
.rce-container-mbox:hover .rce-mbox-remove-right,
.rce-container-mbox:hover .rce-mbox-reply-btn-left,
.rce-container-mbox:hover .rce-mbox-reply-btn-right {
  opacity: 1;
  visibility: visible;
}
.rce-mbox {
  background: rgb(0 0 0 / 10%);
  border-radius: 5px;
  border-top-left-radius: 0;
  box-shadow: 0px 2px 4px 0px #0003;
  flex-direction: column;
  float: left;
  margin: 3px 5px 3px 16px;
  min-width: 90px;
  padding: 6px 9px 8px;
  position: relative;
}
.rce-mbox.message-focus {
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: 2;
  animation-duration: 1s;
  animation-iteration-count: 2;
  animation-name: message-box-default-focus;
}
@-webkit-keyframes message-box-default-focus {
  0% {
    background-color: #fff;
  }
  to {
    background-color: #dfdfdf;
  }
}
.rce-mbox-body {
  margin: 0;
  padding: 0;
  position: relative;
}
.rce-mbox.rce-mbox-right {
  background: #f1f3f405;
  border-top-left-radius: 5px;
  border-top-right-radius: 0;
  float: right;
  margin-left: 5px;
  margin-right: 16px;
}
.rce-mbox.rce-mbox-right.message-focus {
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: 2;
  animation-duration: 1s;
  animation-iteration-count: 2;
  animation-name: message-box-right-focus;
}
@-webkit-keyframes message-box-right-focus {
  0% {
    background-color: #d4f1fb;
  }
  to {
    background-color: #b8dae6;
  }
}
.rce-mbox-text {
  font-size: 13.6px;
  white-space: break-spaces;
  word-break: break-word;
}
.rce-mbox-text-retracted {
  align-items: center;
  display: flex;
  font-style: italic;
  user-select: none;
}
.rce-mbox-text.rce-mbox-text-retracted svg {
  margin-right: 3px;
}
.rce-mbox-text-retracted.left {
  color: #555555b3 !important;
}
.rce-mbox-text-retracted.right {
  color: #efefefb3 !important;
}
.rce-mbox-text:after {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
}
.rce-mbox-time {
  align-items: center;
  bottom: -5px;
  /* color: #0000009c; */
  opacity: 0.6;
  display: flex;
  font-size: 12px;
  justify-content: flex-end;
  right: -4px;
  text-align: right;
  user-select: none;
}
.rce-mbox-time.non-copiable:before {
  content: attr(data-text);
}
.rce-mbox-time-block {
  align-items: center;
  /* background: linear-gradient(0deg, #00000054, #0000); */
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  bottom: 0;
  /* color: black#fff; */
  display: flex;
  justify-content: flex-end;
  left: 0;
  margin-left: -6px;
  margin-right: -6px;
  padding-bottom: 0px;
  padding-right: 3px;
  padding-top: 0px;
  right: 0;
}
.rce-mbox--clear-padding {
  padding-bottom: 3px;
}
.rce-mbox.rce-mbox--clear-notch {
  border-radius: 5px 5px 5px 5px !important;
}
.rce-mbox-right-notch {
  /* fill: rgba(221, 221, 221, 0.12); */
  fill: #f1f3f405;
  /* filter: drop-shadow(2px 0 1px rgba(0, 0, 0, 0.2)); */
  height: 12px;
  position: absolute;
  right: -12px;
  top: 0;
  width: 12px;
}
.rce-mbox-right-notch.message-focus {
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: 2;
  animation-duration: 1s;
  animation-iteration-count: 2;
  animation-name: message-right-notch-focus;
}
@-webkit-keyframes message-right-notch-focus {
  0% {
    fill: #d4f1fb;
  }
  to {
    fill: #b8dae6;
  }
}
.rce-mbox-left-notch {
  fill: rgb(0 0 0 / 10%);
  height: 12px;
  left: -12px;
  position: absolute;
  top: 0;
  width: 12px;
}
.rce-mbox-left-notch.message-focus {
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: 2;
  animation-duration: 1s;
  animation-iteration-count: 2;
  animation-name: message-left-notch-focus;
}
@-webkit-keyframes message-left-notch-focus {
  0% {
    fill: #fff;
  }
  to {
    fill: #dfdfdf;
  }
}
.rce-mbox-title {
  align-items: center;
  color: #4f81a1;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  margin: 0 0 8px;
  user-select: none;
}
.rce-mbox-title:hover {
  text-decoration: underline;
}
.rce-mbox-title--clear {
  margin-bottom: 5px;
}
.rce-mbox-status {
  font-size: 15px;
  height: 15px;
  margin-left: 3px;
}
.rce-mbox-title > .rce-avatar-container {
  margin-right: 5px;
}
.rce-mbox-forwardedMessage {
  border-radius: 5px;
  display: flex;
  margin: -1px -6px 6px;
  overflow: hidden;
  padding: 0 0 0 5px;
  position: relative;
  transition: 0.2s;
  user-select: none;
}
.rce-mbox-forwarded-message {
  align-items: center;
  display: flex;
  font-size: 13px;
  font-style: italic;
  padding: 1px;
}
.rce-mbox-photo {
  margin-left: -6px;
  margin-right: -6px;
  margin-top: -3px;
}
.rce-mbox-photo .rce-mbox-text {
  margin: auto;
  /* max-width: 300px; */
  padding: 2px 6px;
}
.rce-mbox-photo--img {
  border-radius: 5px;
  display: flex;
  justify-content: center;
  max-height: 300px;
  overflow: hidden;
  position: relative;
}
.rce-mbox-photo--img__block {
  background-color: #00000080;
  border-radius: 5px;
  bottom: 0;
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.rce-mbox-photo--img img {
  /* height: 100%; */
  max-height: 150px;
  /* max-height: 200px; */
  /* min-height: 100px; */
  max-width: 100%;
  user-select: none;
}
.rce-mbox-photo--img__block-item {
  cursor: pointer;
  height: 100px;
  margin: auto;
  width: 100px;
}
.rce-mbox-photo--download {
  background: none;
  border: 1px solid #eee;
  border-radius: 100%;
  color: #efe;
  display: flex;
  font-size: 3.2em;
  height: 100px;
  justify-content: center;
  outline: none;
  width: 100px;
}
.rce-mbox-photo--download:hover {
  opacity: 0.7;
}
.rce-mbox-photo--download:active {
  opacity: 0.3;
}
.rce-mbox-photo--error {
  align-items: center;
  background: none;
  /* color: #eaeaea; */
  display: flex;
  font-size: 70px;
  justify-content: center;
}
.rce-mbox-file {
  padding-bottom: 2px;
}
.rce-mbox-file > button {
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 5px;
  box-shadow: inset 0px 0px 1px #7d50ff7d;
  cursor: pointer;
  display: flex;
  margin-left: -6px;
  margin-right: -6px;
  margin-top: -3px;
  max-width: 500px;
  min-height: 52px;
  outline: none;
  padding: 5px 0;
  user-select: none;
}
.rce-mbox-file > button > * {
  padding: 0 10px;
}
.rce-mbox-file--icon {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 30px;
}
.rce-mbox-file--size {
  font-size: 10px;
  margin-top: 3px;
  max-width: 52px;
}
.rce-mbox-file--size,
.rce-mbox-file--text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rce-mbox-file--text {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  font-size: 13.6px;
  overflow: hidden;
  white-space: break-spaces;
}
.rce-mbox-file--buttons {
  align-items: center;
  display: flex;
  font-size: 30px;
}
.rce-mbox-file--buttons .rce-error-button {
  display: inherit;
}
.rce-mbox-file--loading {
  font-size: 15px;
  height: 40px;
  width: 40px;
}
.rce-container-smsg,
.rce-smsg {
  align-items: center;
  display: flex;
  justify-content: center;
}
.rce-smsg {
  background: transparent;
  border-radius: 10px;
  box-shadow: 1px 1px 1px 1px #0003;
  flex-direction: column;
  float: left;
  margin: 5px 0;
  max-width: 70%;
  padding: 6px 9px 8px;
  position: relative;
  user-select: none;
}
.rce-smsg-text {
  display: inline-block;
  font-size: 14px;
  text-align: center;
}
.rce-mbox-location {
  align-items: center;
  border-radius: 5px;
  display: flex;
  height: 150px;
  justify-content: center;
  margin-left: -6px;
  margin-right: -6px;
  margin-top: -3px;
  overflow: hidden;
  position: relative;
  width: 250px;
}
.rce-mbox-location-img {
  width: 100%;
}
.rce-mbox-location-text {
  margin-left: -6px;
  margin-right: -6px;
  padding: 5px 0;
  width: 250px;
}
.rce-mbox-spotify {
  margin-left: -6px;
  margin-right: -6px;
  margin-top: -2px;
}
.rce-mbox-reply,
.rce-mbox-spotify {
  border-radius: 5px;
  display: flex;
  overflow: hidden;
}
.rce-mbox-reply {
  background: #ececec;
  cursor: pointer;
  font-size: 12px;
  margin: 0px -6px 6px;
  padding: 0 0 0 5px;
  position: relative;
  transition: 0.2s;
  user-select: none;
}
.rce-mbox-reply.rce-mbox-reply-border {
  border-left: 5px solid;
}
.rce-mbox-reply:hover {
  opacity: 0.85;
}
.rce-mbox-reply-left {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 3px 0;
}
.rce-mbox-reply-owner {
  font-size: 13px;
}
.rce-mbox-reply-message {
  color: #5a5a5a;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rce-mbox-reply-right {
  height: 40px;
  width: 40px;
}
.rce-mbox-reply-right img {
  width: 100%;
}
.rce-mbox-reply-text {
  margin-left: -6px;
  margin-right: -6px;
  padding: 5px 0;
  width: 250px;
}
.rce-mbox-mtmg {
  align-content: center;
  display: flex;
  justify-content: center;
  max-width: 425px;
  min-width: 425px;
  padding-bottom: 13px;
}
.rce-mtmg {
  background: #f5f5f5;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  float: left;
  margin: 5px 0;
  position: relative;
  width: 100%;
}
.rce-mtmg-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 300px;
}
.rce-mtmg-subject {
  display: inline-block;
  font-size: 15px;
  padding: 5px 9px;
  text-align: start;
}
.rce-mtmg-toogleItem {
  height: 100%;
  width: 100%;
}
.rce-mtmg-body {
  background: #6264a7;
  color: #fff;
  display: flex;
  font-size: 15px;
  height: 50px;
  justify-content: space-between;
}
.rce-mtmg-body:hover {
  opacity: 0.9;
}
.rce-mtmg-item {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 0 10px;
}
.rce-mtmg-item > svg {
  height: 23px;
  width: 23px;
}
.rce-mtmg-content {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}
.rce-mtmg-date {
  color: #cecece;
  font-size: 13px;
}
.rce-mtmg-body-bottom {
  color: #6264a7;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 13px;
  padding: 9px;
}
.rce-mtmg-bottom--tptitle {
  align-items: center;
  display: flex;
  justify-content: center;
}
.rce-mtmg-body-bottom--bttitle > svg,
.rce-mtmg-bottom--tptitle > svg {
  color: #6264a7a1;
}
.rce-mtmg-toogleContent {
  display: none;
  flex-direction: column;
  height: auto;
  min-height: 60px;
}
.rce-mtmg-toogleContent--click {
  display: flex;
}
.rce-mtmg-right-icon {
  background: #0000 !important;
  cursor: pointer;
  height: 100%;
  right: 10px;
}
.rce-mtmg-body .rce-dropdown-container {
  height: 100%;
}
.rce-mtmg-right-icon > svg {
  height: 23px;
  width: 23px;
}
.rce-mitem {
  display: flex;
  padding: 10px 8px;
}
.rce-mitem-event:hover,
.rce-mitem:hover {
  background: #ececec;
}
.rce-mitem-event {
  user-select: none;
}
.rce-mitem-body {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.rce-mitem-body-content {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
}
.rce-mitem-body--top {
  align-items: center;
  display: flex;
  margin-bottom: 2px;
  user-select: none;
}
.rce-mitem-body--top-title {
  color: #6264a7;
  font-size: 15px;
  font-weight: 600;
  padding: 0 15px 0 0;
  text-transform: capitalize;
}
.rce-mitem-body--top-title:hover {
  cursor: pointer;
  text-decoration: underline;
}
.rce-mitem-body--bottom-title {
  color: #252525;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Open Sans,
    Helvetica Neue,
    sans-serif;
  font-size: 13px;
}
.rce-mitem-body--top-time {
  color: #0006;
  font-size: 12px;
}
.rce-mitem-bottom-body {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  padding: 10px 8px;
}
.rce-mitem-body.avatar {
  padding: 8px 10px 0 0;
}
.rce-mitem.avatar {
  padding: 8px 5px 0 0;
}
.rce-mitem.no-avatar {
  padding: 8px 10px 0 0;
}
.rce-mitem.no-avatar > svg {
  height: 19px;
  width: 19px;
}
.rce-mitem.avatar img {
  background: #ccc;
  border: none !important;
  border-radius: 100%;
  height: 22px;
  width: 22px;
}
.rce-mitem-body.avatar > svg {
  height: 19px;
  width: 19px;
}
.rce-mitem-bottom-body-top {
  display: flex;
  flex-direction: column;
}
.rce-mitem-bottom-body-top-title > svg {
  padding: 0 7px 0 0;
}
.rce-mitem-avatar-content {
  display: flex;
  position: absolute;
  right: 10px;
}
.rce-mitem-avatar {
  display: flex;
  padding: 0 3px 0 0;
}
.rce-mitem-tooltip {
  display: inline;
  position: relative;
}
.rce-mitem-tooltip-text {
  margin: 5px;
}
.rce-mitem-tooltip-text:after {
  border-bottom: 11px solid #444;
  border-left: 8px solid #0000;
  border-right: 8px solid #0000;
  content: "";
  left: 15%;
  opacity: 0;
  position: absolute;
  top: 29px;
  transition: opacity 0.8s linear 0.2s;
}
.rce-mitem-tooltip[tooltip]:after {
  background: #444;
  border-radius: 8px;
  color: #fff;
  content: attr(tooltip);
  display: flex;
  font-size: 14px;
  justify-content: center;
  max-width: 415px;
  min-width: 415px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  right: -13px;
  top: 40px;
  transition: opacity 0.8s linear 0.2s;
  z-index: 1;
}
.rce-mitem-tooltip-text:hover:after {
  opacity: 1;
  transition: opacity 0.8s linear;
}
.rce-mitem-tooltip[tooltip]:hover:after {
  opacity: 1;
  transition: opacity 0.8s linear 0.1s;
}
.rce-mitem-tooltip[tooltip]:hover .rce-mitem-tooltip-text:after {
  opacity: 1;
}
.rce-mitem-length {
  align-items: center;
  background: #e48989;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 14px;
  height: 25px;
  text-align: center;
  width: 25px;
}
.rce-mitem-avatar img {
  background: #ccc;
  border: none !important;
  border-radius: 100%;
  height: 50px;
  width: 50px;
}
.rce-mtmg-call-record {
  background: #eaeaea;
  height: 85%;
  margin-top: 11px;
  width: 350px;
}
.rce-mtmg-call-body {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  position: relative;
}
.rce-mtmg-call-avatars {
  height: 100px !important;
  position: relative;
  width: 140px !important;
}
.rce-mtmg-call-avatars img {
  background: #ccc;
  cursor: pointer;
  height: 100% !important;
  width: 100% !important;
}
.rce-mtmg-call-body-title {
  display: flex;
  flex-direction: column;
  left: 15px;
  position: relative;
  top: 30px;
}
.rce-mtmg-call-body-bottom,
.rce-mtmg-call-body-title > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 185px;
}
.rce-mtmg-call-body-bottom {
  color: #505050;
  font-size: 12px;
}
.rce-mtmg-record-time {
  background: #000000cf;
  border-radius: 5px;
  bottom: 5px;
  color: #fff;
  font-size: 13px;
  padding: 4px;
  position: absolute;
  right: 5px;
}
.rce-avatar-container {
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.rce-avatar-container .rce-avatar {
  height: 100%;
  width: 100%;
}
.rce-avatar-container.flexible .rce-avatar {
  border-radius: unset !important;
  height: auto !important;
  overflow: unset !important;
  width: 100% !important;
}
.rce-avatar-container.default {
  height: 25px;
  width: 25px;
}
.rce-avatar-container.rounded {
  border-radius: 5px;
}
.rce-avatar-container.circle {
  border-radius: 100%;
}
.rce-avatar-container.xsmall {
  height: 30px;
  width: 30px;
}
.rce-avatar-container.small {
  height: 35px;
  width: 35px;
}
.rce-avatar-container.medium {
  height: 40px;
  width: 40px;
}
.rce-avatar-container.large {
  height: 45px;
  width: 45px;
}
.rce-avatar-container.xlarge {
  height: 55px;
  width: 55px;
}
@keyframes avatarLazy {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
.rce-avatar-lazy {
  animation: avatarLazy 2s ease-in-out infinite normal;
}
.rce-avatar-container.rce-citem-avatar-encircle-status {
  box-sizing: border-box;
  position: relative;
}
.rce-avatar-letter {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 20px;
  justify-content: center;
  margin-top: 5px;
}
.rce-avatar-letter-background {
  border-radius: 20px;
  height: 35px;
  width: 35px;
}
.rce-dropdown-container {
  position: relative;
}
.rce-dropdown {
  align-items: center;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 #a3a3a3;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  justify-content: center;
  min-width: 100%;
  padding: 8px 15px;
  position: absolute;
  transform: scale(0);
  transform-origin: left top;
  z-index: 99999;
}
.rce-dropdown-title {
  color: "#000";
  font-size: 14px;
  font-weight: 500;
}
.rce-dropdown.dropdown-show {
  animation: dropdown-scaling 0.2s ease forwards;
  display: flex;
}
@keyframes dropdown-scaling {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
    transform: scale(1.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.rce-dropdown.dropdown-hide {
  animation: dropdown-reverse-scaling 0.2s ease forwards;
  display: flex;
}
@keyframes dropdown-reverse-scaling {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}
.rce-dropdown-open__nortwest {
  left: 0;
  margin-top: 5px;
  top: 100%;
  transform-origin: left top;
}
.rce-dropdown-open__norteast {
  margin-top: 5px;
  right: 0;
  top: 100%;
  transform-origin: right top;
}
.rce-dropdown-open__southwest {
  bottom: 100%;
  left: 0;
  margin-bottom: 5px;
  transform-origin: left bottom;
}
.rce-dropdown-open__southeast {
  bottom: 100%;
  margin-bottom: 5px;
  right: 0;
  transform-origin: right bottom;
}
.rce-dropdown ul {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.rce-dropdown ul li {
  border-bottom: 1px solid #e9e9e9;
  box-sizing: border-box;
  color: #767676;
  cursor: pointer;
  font-size: 16px;
  padding: 8px;
  user-select: none;
  white-space: nowrap;
  width: 100%;
}
.rce-dropdown ul li:last-child {
  border: none;
}
.rce-dropdown ul li:hover a {
  color: #3a6d8c;
}
.rce-dropdown.fade {
  animation: dropdown-fade 0.5s ease forwards;
  opacity: 0;
  transform: scale(1);
}
@keyframes dropdown-fade {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.rce-button {
  background: #3979aa;
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  flex-direction: row;
  font-size: 12px;
  justify-content: center;
  outline: none;
  padding: 8px;
  position: relative;
  text-align: center;
  transition: all 0.15s ease;
  user-select: none;
}
.rce-button,
.rce-button-icon--container {
  align-items: center;
  display: flex;
}
.rce-button:hover {
  opacity: 0.8;
}
.rce-button:active {
  opacity: 0.6;
}
.rce-button.outline {
  background: #0000 !important;
  border: 1px solid #3979aa;
  color: #3979aa;
}
.rce-button.outline:hover {
  opacity: 0.6;
}
.rce-button.outline:active {
  opacity: 0.3;
}
.rce-button.transparent {
  background: #0000 !important;
}
.rce-button.transparent:hover {
  opacity: 0.6;
}
.rce-button.transparent:active {
  opacity: 0.3;
}
.rce-button-icon {
  display: flex;
  font-size: 18px;
  padding: 0 3px;
  position: relative;
}
.rce-button-badge {
  align-items: center;
  background: #f64b34;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  font-size: 10px;
  justify-content: center;
  padding: 4px;
  position: absolute;
  right: -7px;
  top: -7px;
}
.rce-button.circle {
  border: 1px solid #3979aa;
  border-radius: 100%;
  min-height: 35px;
  min-width: 35px;
}
.rce-mbox-video {
  margin-left: -6px;
  margin-right: -6px;
  margin-top: -3px;
  /* min-height: 190px; */
}
.rce-mbox-video.padding-time {
  padding-bottom: 12px;
}
.rce-mbox-video .rce-mbox-text {
  margin: auto;
  max-width: 300px;
  padding: 2px 6px;
}
.rce-mbox-video--video {
  border-radius: 5px;
  display: flex;
  justify-content: center;
  max-height: 200px;
  overflow: hidden;
  position: relative;
}
.rce-mbox-video--video__block {
  background-color: #00000080;
  border-radius: 5px;
  bottom: 0;
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.rce-mbox-video--video img {
  height: 100%;
  min-height: 100px;
  user-select: none;
}
.rce-mbox-video--video video {
  user-select: none;
  width: 100%;
}
.rce-mbox-video--video__block-item {
  cursor: pointer;
  height: 100px;
  margin: auto;
  width: 100px;
}
.rce-mbox-video--download {
  background: none;
  border: 1px solid #eee;
  border-radius: 100%;
  color: #efe;
  display: flex;
  font-size: 3.2em;
  height: 100px;
  justify-content: center;
  outline: none;
  width: 100px;
}
.rce-mbox-video--download:hover {
  opacity: 0.7;
}
.rce-mbox-video--download:active {
  opacity: 0.3;
}
.rce-mbox-video--error {
  align-items: center;
  background: none;
  color: #eaeaea;
  display: flex;
  font-size: 70px;
  justify-content: center;
}
.rce-mbox-audio {
  max-width: 300px;
  audio {
    min-width: 250px;
    width: inherit;
  }
  /* padding-bottom: 0px; */
}
audio:focus {
  outline: none;
}
.rce-mtlink {
  min-width: 400px;
  padding-bottom: 15px;
}
.rce-mtlink-content {
  background-color: #e2dfec;
  border-radius: 4px;
  justify-content: space-between;
  padding: 10px;
}
.rce-mtlink-content,
.rce-mtlink-item {
  align-items: center;
  display: flex;
  overflow: hidden;
}
.rce-mtlink-item {
  color: #6c687c;
  justify-content: flex-start;
  max-width: 100%;
  min-width: 240px;
  user-select: none;
}
.rce-mtlink-title {
  color: #07030a;
  font-size: 15px;
  padding-left: 7px;
}
.rce-mtlink-btn {
  background: #ededed;
  border-radius: 4px;
  display: flex;
  margin-left: 5px;
}
.rce-mtlink-btn-content {
  border-right: 1px solid;
  cursor: pointer;
  padding: 4px;
}
.rce-mtlink-btn-content:last-child {
  border-right: none;
}
.rce-container-citem {
  display: block;
  flex-direction: column;
  min-width: 240px;
  overflow: hidden;
}
.rce-citem,
.rce-container-citem.rce-citem-status-encircle {
  position: relative;
}
.rce-citem {
  background: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 72px;
  max-width: 100%;
  min-width: 240px;
  overflow: hidden;
  user-select: none;
}
.rce-citem:hover {
  background: #f9f9f9;
}
.rce-citem-avatar {
  flex-direction: column;
  padding: 0 15px 0 13px;
  position: relative;
}
.rce-citem-avatar,
.rce-citem-status {
  align-items: center;
  display: flex;
  justify-content: center;
}
.rce-citem-status {
  background: #ccc;
  border-radius: 100%;
  bottom: 10px;
  height: 20px;
  position: absolute;
  right: 10px;
  width: 20px;
}
.rce-citem-avatar.rce-citem-status-encircle .rce-citem-status {
  background: #0000;
  border-radius: 100%;
  bottom: 0;
  height: 100%;
  left: 0;
  margin: auto;
  right: 0;
  top: 0;
  width: 100%;
}
.rce-citem-avatar img {
  background: #ccc;
  border: none !important;
  border-radius: 100%;
  font-size: 10px;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 50px;
}
.rce-citem-body {
  border-bottom: 1px solid #0000000d;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding-right: 15px;
}
.rce-citem-body--top {
  display: flex;
}
.rce-citem-body--bottom {
  display: flex;
  margin-top: 4px;
}
.rce-citem-body--bottom-title,
.rce-citem-body--top-title {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rce-citem-body--top-title {
  font-size: 16px;
}
.rce-citem-body--bottom-title {
  color: #555;
  font-size: 15px;
}
.rce-citem-body--top-time {
  color: #0006;
  font-size: 12px;
}
.rce-citem-body--bottom-status {
  margin-left: 3px;
}
.rce-citem-body--bottom-status span {
  background: red;
  border-radius: 100%;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}
.rce-citem-body--bottom-status span,
.rce-citem-body--bottom-status-icon {
  align-items: center;
  display: flex;
  height: 18px;
  justify-content: center;
  width: 18px;
}
.rce-citem-body--bottom-status-icon {
  margin-left: 3px;
  position: relative;
}
.rce-citem-body--bottom-tools {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 0;
  justify-content: center;
  left: -999px;
  opacity: 0;
  position: absolute;
  transition:
    height 0.5s ease,
    opacity 1s ease;
}
.rce-citem:hover .rce-citem-body--bottom-tools {
  height: 100%;
  left: 0;
  opacity: 1;
  position: relative;
}
.rce-citem-body--bottom-tools-item-hidden-hover {
  height: 100%;
  opacity: 0.3;
  transition: 0.5s ease;
}
.rce-citem:hover .rce-citem-body--bottom-tools-item-hidden-hover {
  height: 0;
  left: -999px;
  opacity: 0;
  position: absolute;
  transition: 0.5s ease;
}
.rce-citem-body--bottom-tools-item {
  height: 18px;
  width: 18px;
}
.rce-citem-body--bottom-tools-item svg {
  fill: #575757;
  height: 18px;
  width: 18px;
}
.rce-citem-body--subinfo {
  display: flex;
}
.rce-container-citem.subitem .rce-citem {
  height: 40px;
  padding-left: 30px;
}
.rce-container-citem.subitem .rce-citem-body--top .rce-citem-body--top-title {
  font-size: 12px !important;
}
.rce-container-citem.subitem .rce-citem-body--top .rce-citem-body--top-time {
  font-size: 10px !important;
}
.rce-container-citem.subitem .rce-citem-body--bottom * {
  font-size: 12px !important;
}
.rce-container-citem.subitem .rce-citem-body--bottom-status span {
  align-items: center;
  background: red;
  border-radius: 100%;
  color: #fff;
  display: flex;
  font-size: 10px !important;
  font-weight: 700;
  height: 14px;
  justify-content: center;
  text-align: center;
  width: 14px;
}
.rce-container-citem.subitem .rce-citem-body--bottom {
  margin-top: unset;
}
.rce-citem-expand-button {
  background: #0000;
  border: none;
  color: teal;
  cursor: pointer;
  padding: 2px 15px;
}
.rce-citem-expand-button:hover {
  background-color: #eee;
}
.rce-sublist-container {
  position: relative;
}
.rce-container-clist {
  display: block;
  overflow: auto;
}
.rce-container-mlist {
  display: flex;
  position: relative;
  width: 100%;
}
.rce-mlist {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  position: relative;
}
.rce-mlist-down-button {
  align-items: center;
  background: #fff;
  border-radius: 100%;
  bottom: 15px;
  box-shadow:
    0 1px 1px 0 #0000000d,
    0 2px 5px 0 #0000001a;
  color: #333;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  right: 10px;
  transition: 0.2s;
  width: 40px;
}
.rce-mlist-down-button:hover {
  opacity: 0.7;
}
.rce-mlist-down-button--badge {
  align-items: center;
  border-radius: 100%;
  color: #fff;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  height: 20px;
  justify-content: center;
  position: absolute;
  right: -5px;
  text-align: center;
  top: -5px;
  width: 20px;
}
.rce-container-mtitem {
  display: block;
}
.rce-container-mtitem,
.rce-mtitem {
  flex-direction: column;
  min-width: 240px;
  overflow: hidden;
}
.rce-mtitem {
  background: #fff;
  border-bottom: 1px solid #0000000d;
  display: flex;
  max-width: 100%;
  position: relative;
  user-select: none;
}
.rce-mtitem:hover {
  background: #fbfbfb;
}
.rce-mtitem-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}
.rce-mtitem-subject {
  color: #333;
  font-size: 15px;
  margin-top: 5px;
  max-height: 35px;
  overflow: hidden;
  padding: 0 10px;
  text-overflow: ellipsis;
}
.rce-mtitem-body {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  padding: 0 10px;
}
.rce-mtitem-body--avatars {
  display: flex;
  flex: 1;
  opacity: 0.7;
  overflow: hidden;
}
.rce-mtitem-body--functions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 70px;
}
.rce-mtitem-footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 0 10px;
}
.rce-mtitem-body--avatars .rce-avatar-container {
  border: 2px solid #fff;
  margin-left: -10px;
}
.rce-mtitem-body--avatars .rce-avatar-container:first-child {
  margin: 0;
}
.rce-mtitem-letter {
  align-items: center;
  background: #e48989;
  color: #fff;
  display: flex;
  justify-content: center;
}
.rce-mtitem-button {
  background: #5ba7c5;
  display: flex;
  transition: 0.3s;
}
.rce-mtitem-button,
.rce-mtitem-closable {
  align-items: center;
  border-radius: 100%;
  color: #fff;
  font-size: 25px;
  justify-content: center;
  padding: 3px;
}
.rce-mtitem-closable {
  background: #ff4a4f;
  display: none;
  margin-right: 7px;
}
.rce-mtitem:hover .rce-mtitem-closable {
  cursor: pointer;
  display: flex;
}
.rce-mtitem-share {
  align-items: center;
  color: #5ba7c5;
  display: flex;
  font-size: 30px;
  justify-content: center;
  left: -10px;
  margin: -10px 0;
  position: relative;
  transition: 0.3s;
}
.rce-mtitem-button:hover,
.rce-mtitem-share:hover {
  cursor: pointer;
  opacity: 0.8;
}
.rce-mtitem-date {
  color: #9f9f9f;
  font-size: 13px;
}
.rce-container-mtlist {
  display: block;
  overflow: auto;
}
.rce-container-input {
  align-items: center;
  background: #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  min-width: 100%;
}
.rce-input {
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  color: #333;
  flex: 1;
  font-size: 14px;
  height: 40px;
  outline: none;
  padding: 0 5px;
}
.rce-input-textarea {
  height: 37px;
  padding: 10px 5px;
  resize: none;
}
.rce-input-buttons {
  margin: 5px;
}
.rce-input-buttons,
.rce-input-buttons > * {
  display: flex;
  flex-direction: row;
}
.rce-input-buttons .rce-button:nth-child(2n) {
  margin-left: 5px;
  margin-right: 5px;
}
.rce-input-buttons .rce-button:last-child {
  margin-right: 0;
}
.rce-left-padding {
  padding-left: 10px;
  padding-right: 0 !important;
}
.rce-right-padding {
  padding-left: 0 !important;
  padding-right: 10px;
}
.rce-input::placeholder {
  color: #afafaf;
}
.rce-navbar {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}
.rce-navbar.light {
  background: #f4f4f4;
}
.rce-navbar.dark {
  background: #2f414c;
}
.rce-navbar-item {
  align-items: center;
  justify-content: flex-start;
}
.rce-navbar-item,
.rce-navbar-item > * {
  display: flex;
  flex-direction: row;
}
.rce-navbar-item > * > * {
  margin-left: 5px;
}
.rce-sbar {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  padding: 10px;
}
.rce-sbar.light {
  background: #f4f4f4;
}
.rce-sbar.dark {
  background: #2f414c;
}
.rce-sbar-item {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 100%;
}
.rce-sbar-item > * {
  display: flex;
  flex-direction: column;
}
.rce-sbar-item__center {
  margin: 15px 0;
}
.rce-popup-wrapper {
  align-items: center;
  background: #ffffffb3;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999999999;
}
.rce-popup {
  align-items: stretch;
  animation: popup-scaling 0.4s ease forwards;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 25px -2px #4f4f4f;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100px;
  padding: 0 10px;
  width: 400px;
}
@keyframes popup-scaling {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.2);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.rce-popup-header {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 18px 8px;
}
.rce-popup-content {
  box-sizing: border-box;
  font-size: 14px;
  padding: 8px;
}
.rce-popup-content * {
  margin: 0;
}
.rce-popup-footer {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 18px 8px;
}
.rce-popup-footer > * {
  margin-left: 5px;
}
