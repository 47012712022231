@import url("https://fonts.googleapis.com/css?family=Merriweather+Sans");
main {
  align-items: center;
  /* background: #fff; */
  display: flex;
  flex-direction: column;
  font-family: "Merriweather Sans", sans-serif;
  height: calc(100dvh - 24px);
  justify-content: center;
  width: calc(100vw - 24px);
  #errorText {
    font-size: 22px;
    margin: 14px 0;
  }
  #errorLink {
    background-color: transparent;
    border: 1px solid;
    color: #000;
    font-size: 20px;
    padding: 12px;
    text-decoration: none;
    transition: all 0.5s ease-in-out;
    &:hover,
    &:active {
      background: #000;
      color: #fff;
    }
  }
  #g6219 {
    animation: an1 12s 0.5s infinite ease-out;
    transform-origin: 85px 4px;
  }
}

@keyframes an1 {
  0% {
    transform: rotate(0);
  }
  5% {
    transform: rotate(3deg);
  }
  15% {
    transform: rotate(-2.5deg);
  }
  25% {
    transform: rotate(2deg);
  }
  35% {
    transform: rotate(-1.5deg);
  }
  45% {
    transform: rotate(1deg);
  }
  55% {
    transform: rotate(-1.5deg);
  }
  65% {
    transform: rotate(2deg);
  }
  75% {
    transform: rotate(-2deg);
  }
  85% {
    transform: rotate(2.5deg);
  }
  95% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(0);
  }
}
