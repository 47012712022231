/* @font-face {
      font-family: "Gilroy-Regular";
      font-style: normal;
      font-weight: normal;
      src: url("./style/fonts/Gilroy-Regular.ttf") format("truetype");
 }
 @font-face {
      font-family: "Gilroy-Bold";
      font-style: normal;
      font-weight: normal;
      src: url("./style/fonts/Gilroy-Bold.ttf") format("truetype");
 }  */
:root {
  --app-height: 100%;
  --inputcolor: "#fff";
}

#webpack-dev-server-client-overlay {
  display: none;
}

#ReactSimpleImageViewer {
  /* min-height: 100vh; *
  /*  max-width: 650px; */
  img {
    background: white;
    max-height: 70%;
  }
}

#app {
  /* min-height: 100vh; */
  font-family: "Open Sans";
  font-size: 14px;
  /* height: var(--app-height); */
  /*  margin: 20px 0 50px 0; */
  /* height: auto; */
  /*  max-width: 650px; */
  margin: 0 auto;
}

@supports (padding-top: constant(safe-area-inset-top)) {
  body {
    padding: env(safe-area-inset-top) env(safe-area-inset-right)
      env(safe-area-inset-bottom) env(safe-area-inset-left);
  }
}

body {
  /* height: var(--app-height); */
  padding-left: calc(
    var(--tg-safe-area-inset-left) + var(--tg-content-safe-area-inset-left)
  );
  padding-right: calc(
    var(--tg-safe-area-inset-right) + var(--tg-content-safe-area-inset-right)
  );

  .fade-enter {
    opacity: 0;
    z-index: 1;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
  }
}
.ptr-element {
  color: #aaa;
  height: 0px;
  left: 0;
  position: sticky;
  text-align: center;
  top: 0;
  transition: all;
  width: 100%;
  z-index: 10;
}

.ptr-element .genericon {
  font-size: 34px;
  height: auto;
  margin-top: 5px;
  opacity: 0.6;
  transform: rotate(90deg);
  transition: all 0.25s ease;
  width: auto;
}
.ptr-refresh .ptr-element .genericon {
  transform: rotate(270deg);
}
.ptr-loading .ptr-element .genericon,
.ptr-reset .ptr-element .genericon {
  display: none;
}

.audio-main {
  /* background-color: #ffffff2e; */
  background-color: transparent !important;
  box-shadow: none !important;
  color: inherit !important;
  width: 100% !important;

  .audio-recorder-mic,
  .audio-recorder-options {
    display: none !important;
  }
  .audio-recorder-timer,
  .audio-recorder-status {
    color: inherit !important;
  }
}
.loading {
  display: inline-block;
  display: none;
  margin: 12px 0 0 5px;
  max-height: 20px;
  opacity: 0.4;
  text-align: center;
}
.ptr-loading .loading {
  display: block;
}

.loading span {
  animation: ptr-loading 0.4s infinite alternate;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin-right: 3px;
  transform: scale(0.3);
  vertical-align: middle;
  width: 10px;
}

.loading-ptr-1 {
  animation-delay: 0 !important;
}

.loading-ptr-2 {
  animation-delay: 0.2s !important;
}

.loading-ptr-3 {
  animation-delay: 0.4s !important;
}

@keyframes ptr-loading {
  0% {
    transform: translateY(0) scale(0.3);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    background-color: #333;
    opacity: 1;
  }
}

.ptr-loading .refresh-view,
.ptr-reset .refresh-view,
.ptr-loading .ptr-element,
.ptr-reset .ptr-element {
  transition: all 0.25s ease;
}

.ptr-reset .refresh-view {
  transform: translate3d(0, 0, 0);
}

.ptr-loading .refresh-view {
  transform: translate3d(0, 30px, 0);
}

/* react-pull-to-refresh CSS */

.styled-input-single {
  display: flex;
  flex-direction: column;
  padding: 4px 0 4px 40px;
  position: relative;
  text-align: left;
  .styled-label {
    cursor: pointer;
    &:before,
    &:after {
      border-radius: 50%;
      content: "";
      position: absolute;
      top: 50%;
    }
    &:before {
      background: #f7f7f7;
      box-shadow: 0 0 1px grey;
      height: 22px;
      left: 0;
      margin: -12px 0 0;
      width: 22px;
    }
    &:after {
      z-index: 1;
      left: 3px;
      width: 16px;
      height: 16px;
      margin: -9px 0 0;
      opacity: 0;
      /* background: #007aff; */
      background: var(--inputcolor);
      transform: translate3d(-40px, 0, 0) scale(0.5);
      transition:
        opacity 0.25s ease-in-out,
        transform 0.25s ease-in-out;
    }
  }

  input[type="radio"],
  input[type="checkbox"] {
    display: none;
    left: 0;
    position: absolute;
    top: 0;
    visibility: hidden;

    &:checked + div {
      &:after {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  }
}

.fadeIn {
  animation: 0.5s fadeIn forwards;
}

.fadeOut {
  animation: 0.5s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-20px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(-20px, 0);
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    /* transform: scale(0.95); */
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
  }

  70% {
    /* transform: scale(1); */
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0);
  }

  100% {
    /* transform: scale(0.95); */
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes shake {
  5% {
    /* transform: rotate(5deg); */
    transform: translate3d(0, 1px, 0);
  }
  10% {
    /* transform: rotate(0deg); */
    transform: translate3d(0, 0px, 0);
  }
  15% {
    /* transform: rotate(-5deg); */
    transform: translate3d(0, 1px, 0);
  }
  20.0% {
    /* transform: rotate(0deg); */
    transform: translate3d(0, 0, 0);
  }
}

@keyframes wobble {
  0% {
    transform: translateX(0%);
  }
  10% {
    transform: translateX(-10%) rotate(-5deg);
  }
  20% {
    transform: translateX(10%) rotate(3deg);
  }
  27% {
    transform: translateX(-5%) rotate(-3deg);
  }
  30% {
    transform: translateX(2%) rotate(2deg);
  }
  35% {
    transform: translateX(-5%) rotate(-1deg);
  }
  40%,
  100% {
    transform: translateX(0%);
  }
}

#form-file-upload {
  margin-top: 10px;
  position: relative;
  text-align: center;
  width: 100%;
}

#input-file-upload {
  display: none;
}

.label-file-upload {
  align-items: center;
  border: 1px dashed rgba(143, 143, 143, 0.245);
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-height: 100px;
  /* border-style: dashed; */
  padding: 4px;

  background-color: #00000003;
  /* &:hover {
    background-color: black;
  } */
}

.label-file-upload:hover {
  background-color: transparent;
}

.box-drag {
  background-color: black;
}

.upload-button {
  cursor: pointer;

  background-color: transparent;
  border: none;
}
